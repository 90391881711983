@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';
@use 'sass:math';
@use './m3-theme';

//core mixins
@include mat.core();

//typography
$custom-typography-config: mat.m2-define-typography-config($font-family: 'General Sans',
    $headline-1: mat.m2-define-typography-level(52px, 78px, 600),
    $headline-2: mat.m2-define-typography-level(48px, 72px, 600),
    $headline-3: mat.m2-define-typography-level(40px, 60px, 600),
    $headline-4: mat.m2-define-typography-level(36px, 54px, 600),
    $headline-5: mat.m2-define-typography-level(32px, 48px, 600),
    $headline-6: mat.m2-define-typography-level(24px, 32px, 600),
    $subtitle-1: mat.m2-define-typography-level(20px, 30px, 500),
    $subtitle-2: mat.m2-define-typography-level(18px, 27px, 500),
    $body-1: mat.m2-define-typography-level(16px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 21px, 500),
    $caption: mat.m2-define-typography-level(12px, 18px, 400),
    $button: mat.m2-define-typography-level(16px, 24px, 500));


@media screen and (min-width: 900px) {

  body,
  html {
    font-family: 'General Sans';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    outline: 0 !important;
    letter-spacing: 0.01rem !important;
  }
}


@include mat.typography-hierarchy($custom-typography-config);




.light-theme {

    // Apply the dark theme by default
    @include  mat.all-component-themes(m3-theme.$light-theme);
    @include mat.core-theme(m3-theme.$light-theme);
    @include mat.button-theme(m3-theme.$light-theme);
    
    // @include mat.system-level-typography($light-theme);
    @include mat.system-level-colors(m3-theme.$light-theme);

    @include mat.color-variants-backwards-compatibility(m3-theme.$light-theme);
  
    --sys-outline: #e7e9ed;
    --sys-outline-variant: #e7e9ed;
    --mat-menu-divider-color: #e7e9ed;
    --sys-surface-container: #ffffff;

}


.dark-theme {

  // Apply the dark theme by default
  @include  mat.all-component-themes(m3-theme.$dark-theme);
  @include mat.core-theme(m3-theme.$dark-theme);
  // @include mat.button-theme($dark-theme);
  
  // @include mat.system-level-typography($dark-theme);
  @include mat.system-level-colors(m3-theme.$dark-theme);

  // @include mat.button-color($dark-theme, $color-variant: secondary);
  @include mat.color-variants-backwards-compatibility(m3-theme.$dark-theme);

  // --sys-outline: #373C49;
  // --sys-outline-variant: #373C49;
  // --mat-menu-divider-color: #373C49;
  // --sys-surface-container: #050B1C;
}


.primary {
  color: var(--sys-primary);
}

.border-primary {
  border-color: var(--sys-primary);
}

.bg-primary {
  background-color: var(--sys-primary);
}

.bg-surface-container-high {
  background-color: var(--sys-surface-container-high)
}

.bg-surface-container {
  background-color: var(--sys-surface-container)
}

.border-outline-variant{
  border-color: var(--sys-outline-variant) !important;
}

.on-surface{
  color: var(--sys-on-surface)
}

.on-surface-variant{
  color: var(--sys-on-surface-variant);
}

.error{
  color: var(--sys-error);
}

.border-error {
  border: solid 1px;
  border-color: var(--sys-error);
}

.surface-container {
  background-color: --sys-surface-container;
  color: --sys-surface-container;
}

.svg-color{
  fill: var(--sys-on-surface);
}
