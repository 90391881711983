/* You can add global styles to this file, and also import other style files */

/* TO-DO: Club all default overrides of html tag css */
div,
span,
p,
h1,
h2,
h3 {
    outline: 0 !important;
}

p {
    margin: 0px !important;
}

.fw-100 {
    font-weight: 100 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

/* Custom CSS */

a {
    cursor: pointer !important;
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

/* colors */

.itd-blue {
    color: #124174 !important;
}

.itd-blue-bg {
    background-color: #124174 !important;
}

/* Blue */

.blue-a900 {
    color: #2962ff !important;
}

.blue-a800 {
    color: #3e71ff !important;
}

.blue-a700 {
    color: #5381ff !important;
}

.blue-a600 {
    color: #6991ff !important;
}

.blue-a500 {
    color: #7ea0ff !important;
}

.blue-a400 {
    color: #94b0ff !important;
}

.blue-a300 {
    color: #a9c0ff !important;
}

.blue-350 {
    color: #94B0FF !important;
}

.blue-a200 {
    color: #becfff !important;
}

.blue-a100 {
    color: #d4dfff !important;
}

.blue-a50 {
    color: #e0e8ff !important;
}

.blue-25 {
    color: #F0F7FF !important;
}

.blue-bg-a900 {
    background-color: #2962ff !important;
}

.blue-bg-a800 {
    background-color: #3E71FF !important;
}

.blue-bg-900 {
    background-color: #eaefff !important;
}

.blue-bg-700 {
    background-color: #eef2ff !important;
}

.blue-bg-400 {
    background-color: #f4f7ff !important;
}

.blue-bg-350 {
    background-color: #94B0FF !important;
}

.blue-bg-200 {
    background-color: #f9faff !important;
}

.blue-bg-100 {
    background-color: #fbfcff !important;
}

.blue-bg-50 {
    background-color: #e0e8ff !important;
}

.blue-bg-25 {
    background-color: #F0F7FF !important;
}

.blue-border-a900 {
    border: 1px solid #2962FF !important;
}

.blue-border-a900-2px {
    border: 2px solid #2962FF !important;
}

.blue-dashed-border-350 {
    border: 1.5px dashed #94B0FF;
}

.blue-dashed-border-100 {
    border-width: 1.5px;
    border: dashed #D4DFFF;
}

/* Light Blue */
.light-blue-900 {
    color: #0062ab !important;
}

.light-blue-700 {
    color: #0288d1 !important;
}

.light-blue-400 {
    color: #29b6f6 !important;
}

.light-blue-300 {
    color: #4fc3f7 !important;
}

.light-blue-200 {
    color: #81d4fa !important;
}

.light-blue-50 {
    color: #e0f7ff !important;
}

.light-blue-bg-50 {
    background-color: #E1F5FE !important;
}

.light-blue-bg-700 {
    background-color: #0288D1 !important;
}

.light-blue-bg-900 {
    background-color: #0062AB !important;
}

.light-blue-bg-300 {
    background-color: #29cdff !important;
}

.light-blue-700 {
    color: #0288D1 !important;
}

.light-blue-300 {
    color: #29cdff !important;
}

.light-blue-border-700 {
    border: 1px solid #0288D1 !important;
}

/* White */

.white {
    color: #ffffff !important;
}

.white-bg-500 {
    background-color: #ffffff !important;
}

.white-border {
    border: 1px solid #ffffff !important;
}

.grey-border-dark {
    border: 1px solid #ADADAD !important;
}

.itd-blue {
    color: 1px solid #124174 !important;
}

.meet-red {
    color: 1px solid #d32f2f !important;
}

.black {
    color: 1px solid #000000 !important;
}

/* Blue-Grey */
/* @TO-DO: @Khush: Keep either of primary or blue-grey */

.elite-blue {
    color: #1C1C9E !important;
}

.elite-blue-bg-900 {
    background-color: #1C1C9E !important;
}

.elite-blue-bg-100 {
    background-color: #F4F4FA;
}

.blue-grey-900 {
    color: #263238 !important;
}

.blue-grey-800 {
    color: #37474F;
}

.blue-grey-700 {
    color: #455A64;
}

.blue-grey-600 {
    color: #546E7A;
}

.blue-grey-500 {
    color: #607D8B !important;
}

.blue-grey-400 {
    color: #78909C;
}

.blue-grey-300 {
    color: #90A4AE;
}

.blue-grey-200 {
    color: #B0BEC5;
}

.blue-grey-100 {
    color: #CFD8DC;
}

.blue-grey-50 {
    color: #ECEFF1;
}

.blue-grey-20 {
    color: #DDDDDD !important;
}

.blue-grey-bg-900 {
    background-color: #263238;
}

.blue-grey-bg-800 {
    background-color: #37474F;
}

.blue-grey-bg-700 {
    background-color: #455A64;
}

.blue-grey-bg-600 {
    background-color: #546E7A;
}

.blue-grey-bg-500 {
    background-color: #607D8B;
}

.blue-grey-bg-400 {
    background-color: #78909C;
}

.blue-grey-bg-300 {
    background-color: #90A4AE !important;
}

.blue-grey-bg-200 {
    background-color: #B0BEC5 !important;
}

.blue-grey-bg-100 {
    background-color: #CFD8DC;
}

.blue-grey-bg-50 {
    background-color: #ECEFF1;
}

.blue-grey-border-300 {
    border-color: #90A4AE !important;
}

.blue-grey-border-500 {
    border: 1px solid #607D8B !important;
}

.blue-grey-border-900 {
    border-color: #263238 !important;
}

.twitter-blue {
    color: #1D9BF0 !important;
}

.twitter-blue-bg {
    background-color: #1D9BF0 !important;
}

.twitter-blue-border {
    border-color: #1D9BF0 !important;
}

.red-border {
    border: 1px solid #F44336 !important;
    border-radius: 0.2rem !important;
}

.red-bg-900 {
    background-color: #EF5350 !important;
}

.alert-success-900 {
    color: #5CB660 !important;
}

.alert-success-bg-50 {
    background-color: #edf7ed !important;
}

.alert-success-bg-900 {
    background-color: #5CB660 !important;
}

.alert-success-border-900 {
    border-color: #5CB660 !important;
}

.alert-info-900 {
    color: #094BFF !important;
}

.alert-info-bg-50 {
    background-color: #F5F7FF !important;
}

.alert-info-border-900 {
    border: 1px solid #094BFF !important;
}

.alert-grey-border {
    border: 1px solid #E0E0E0 !important;
}

.alert-warning-900 {
    color: #ff8f00 !important;
}

.alert-warning-bg-50 {
    background-color: #fff9e7 !important;
}

.alert-warning-border-900 {
    border-color: #ff8f00 !important;
}

.alert-error-900 {
    color: #E51717 !important;
}

.alert-error-bg-25 {
    background-color: #FEFAFA !important;
}

.alert-error-bg-50 {
    background-color: #fdeded !important;
}

.alert-error-bg-900 {
    background-color: #E51717 !important;
}

.alert-error-border-900 {
    border: 1px solid #E51717 !important;
    border-radius: 0.2rem !important;
}

.alert-border {
    border-style: solid !important;
    border-width: 1px 1px 1px 4px !important;
    box-shadow: none !important;
}

.alert-border-red-left {
    border-left: 1px solid #E51717 !important;
    border-radius: 0.2rem !important;
    border-style: solid !important;
    border-width: 0px 0px 0px 4px !important;
    box-shadow: none !important;
}

.alert-border-red-top {
    border-top: 1px solid #E51717 !important;
    border-radius: 0.2rem !important;
    border-style: solid !important;
    border-width: 4px 1px 1px 1px !important;
    box-shadow: none !important;
}

.side-nav-selected {
    border-right: 3px solid #2962ff !important;
    background-color: #fbfcff !important;
}

.std-button {
    min-height: 48px !important;
    min-width: 144px !important;
}

.plans-pro-green {
    color: #1de9b6 !important;
}

.plans-plus-yellow {
    color: #ffc20f !important;
}

.numbers-financial-red {
    color: #e53935 !important;
}

.numbers-financial-green {
    color: #429e46 !important;
}

.grey-bg-900 {
    background-color: #EEEEEE !important;
}

.grey-bg-700 {
    background-color: #F1F1F1 !important;
}

.grey-bg-400 {
    background-color: #F6F6F6 !important;
}

.grey-bg-200 {
    background-color: #FAFAFA !important;
}

.grey-bg-100 {
    background-color: #FCFCFC !important;
}

.ls-4 {
    letter-spacing: 4px !important;
}

.mat-round-button {
    border-radius: 2rem !important;
    font-size: 16px !important;
    min-width: 9.2rem !important;
    min-height: 3.2rem;
}

/* ELITE FONT - Playfair Display */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,600&display=swap');

.font-elite {
    font-family: 'Playfair Display' !important;
}

.grey-border {
    border: 1px solid #e0e0e0 !important;
}

.grey-border-dark {
    border: 1px solid #ADADAD !important;
}

/* Add this class to avoid vertical line in mat-form-field  */
.mdc-notched-outline__notch {
    border-right: none !important;
}

.mat-tooltip {
    font-size: 10px !important;
}