@import "common.css";
@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.mobile-container {
  width: 100%;
  padding: 0 16px;
  margin: 0;
}


html, body {
  overflow-x: hidden;
}

.container {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
}

/* .white-snackbar{
  --mdc-snackbar-container-color: white !important;
  --mdc-snackbar-supporting-text-color: #2962FF !important;
  --mdc-snackbar-supporting-text-size: 1rem !important;
} */

.mdc-tab {
  --mdc-typography-button-letter-spacing: 0 !important;
}

input[type=number]{
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.mat-input-wrapper {
  width: 100% !important;
}

.mdc-list {
  padding: 0 !important;
}

.mdc-notched-outline__notch {
  border-right: 0px !important;
}


/* .mdc-tab--active .mdc-tab__text-label {
  color: #2962ff !important;
} */

.mat-bottom-sheet-container {
  padding: 0 !important;
  border-radius: 5px !important;
}

.mat-mdc-paginator-page-size-label {
  display: none !important;
}

.mat-mdc-paginator-range-actions {
  display: none !important;
}

.mat-mdc-paginator-page-size-label {
  display: none !important;
}

.mat-mdc-dialog-panel{
  --mat-dialog-container-max-width: 100%;
}

.mat-mdc-dialog-surface{
  border-radius: 4px !important; 
  color: var(--sys-on-surface)
}

